@import (reference) './wp-content/themes/klimazm/styles/variables';

.theme-klimazm {

    .mobile-menu {
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow-y: auto;
        z-index: 999;
        transition: left @transition-time ease;
    }

    .mobile-menu__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: var(--size-header-height);
        padding: 0 var(--spacing-container-desktop);
        border-bottom: 1px solid #ddd;
    }

    .mobile-menu__title {
        margin-bottom: 0;
    }

    .mobile-menu__body {
        padding: 1rem var(--spacing-container-desktop);

        .menu-item {

            a {
                display: block;
                padding: 0.25rem 0.5rem;
                border-bottom: 1px solid var(--color--border-default);
                color: var(--color-text);

                transition: color 0.3s, background-color 0.3s;
            }

            &:hover a {
                background: var(--color-button-primary-hover);
                color: var(--color-text-inverse);
            }

            & a:focus,
            &.current_page_item a,
            &.current-menu-item a {
                background: var(--color-button-primary-active);
                color: var(--color-text-inverse);
            }
        }
    }

    .mobile-menu__menu {
        margin: 0;
        list-style: none;
    }

    .mobile-menu-trigger,
    .mobile-menu__close {
        cursor: pointer;
    }

    .mobile-menu,
    .mobile-menu__button {
        display: none;
    }

    &.admin-bar .mobile-menu {
        top: @height-admin-bar;
    }

    @media screen and (max-width: @width-mobile-admin-bar-max) {
        &.admin-bar .mobile-menu {
            top: @height-admin-bar-mobile;
        }
    }

    @media screen and (max-width: @width-mobile-max) {

        .mobile-menu,
        .mobile-menu__button {
            display: block;
        }
    }

    @media screen and (max-width: @width-mobile-small-max) {

        .mobile-menu__header {
            padding: 0 var(--spacing-container-mobile);
        }

        .mobile-menu__body {
            padding: 1rem var(--spacing-container-mobile);
        }
    }

    .mobile-menu--open {
        left: 0;
    }
}
